@import "../../../base";

.modal {
  width: 100%;
  height: 70vh;

  &__sec {
    &--head {
      border-bottom: 4px solid #63b8ec;
      padding-bottom: 0.5rem;
      border-radius: 3px;
      border-width: 50%;
    }

    &--body {
      padding-bottom: 60px;
      overflow-y: auto !important;
      overflow-x: hidden !important;

      &-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        
        &-bottom {
          display: grid;
          grid-auto-flow: column;
          gap: 2rem;
          padding-top: 1rem;
          grid-column: 1/3;


          &-relative {
            display: grid;
            grid-auto-flow: column;
            gap: 2rem;
            position: relative;
          }

          &-fixed {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1rem !important;
          }
        }
      }

      &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        column-gap: 2rem;
        @media screen and (max-width: 750px) {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }

    &--btn {
      padding: 1rem;
      background-color: #63b8ec;
      width: 12rem;
      text-align: center;
      color: white;
      border: none;
      border-radius: 2rem;
      font-size: 11px;
      transition: all 0.2s;
      cursor: pointer;

      &-close {
        background-color: #afafaf;
      }

      &:hover {
        background-color: $color-primary-dark;
      }
    }
  }

  .modal__sec--body {

    .projectTarget__table {
      .projectTarget__table--tab-body {
        .cvdata {
          width: 10px !important;
        }

        .cvdata td {
          width: 10px !important;
          max-width: 10px;
        }

        .projectTarget__table--tab-head-th-7 th {

          width: 10px !important;
          max-width: 10px !important;
        }
      }
    }
  }
}

.modal-content {
  padding: 1rem;
  border-radius: 1rem;
  border: none;
}

.rings_css {
  padding-left: 33vh;
}

.action_tablecss {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: start;
}

.modal-header {
  border-bottom: none;
}

.table_heading {
  padding-left: 1.2rem;
}

.modal-body {
  height: auto;
  overflow-x: visible;
  overflow-y: visible;
  position: static;
  display: grid;
  gap: 1rem;
}


.email_button {
  text-align: center;
  font-size: 2rem;
}

.email_removebtn {
  display: flex;
}

.modal-footer {
  border-radius: 1rem;
  border-top: none;
  width: 100%;
  background-color: white;
  padding: 0;
  justify-content: center;
  gap: 1rem;
}

.setting_aligncss {
  display: flex;
}

.modal-dialog {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  margin: 0 !important;
  max-width: none;
}

.modal-open .modal {
  height: 100vh;
  display: flex !important;
  justify-content: center;
  overflow-y: hidden !important;
}



.css-1sjtyi4-menu {
  max-height: 200px;
  overflow-y: auto;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 20px;
}

.project_target_modal {
  height: 31rem;
}

.btn_container .AddFiles {
  border: none;
  background-color: transparent;
  font-size: 21px;
  position: relative;
  width: 10%;
  margin-top: 10px;
  left: 12rem;
  caret-color: transparent;
  display: inline;
}

.mandatory {
  color: red;
}

.doc-label {
  margin-bottom: 0px;
  font-size: 1.3rem;
}

.custom-file-input {
  justify-self: end;
  padding-left: 7px;
  width: 300px;
  height: auto;
  min-height: 35px;
  max-height: 100px;
  outline: none;
  border-radius: 5px;
  grid-row: 2/3;
  grid-column: 1/2;
  display: grid;
  grid-template-columns: 15fr 1fr;
  align-content: center;
  justify-content: center;
  justify-items: start;
  align-items: center;
  font-size: 1.2rem;
  grid-auto-flow: column;
  border: 1px solid hsl(0deg, 0%, 80%);
}

.custom-file {
  display: inline;
}

.custom-select {
  margin-bottom: 1rem;
  max-width: 20rem;
}

.btn_container {
  display: flex;
  margin: 1rem;
}

.file_remove_btn {
  font-size: 2rem;
  color: red;
  margin-bottom: 9px;
  position: relative;
  top: 6px;
  cursor: pointer;
}

.file_remove_btn1 {
  font-size: 2rem;
  color: red;
  margin-bottom: 9px;
  position: relative;
  top: 24px;
  cursor: pointer;
}

.del-upload {
  display: flex;
  gap: 4px;
}

.email_input {
  position: relative;
  right: 6rem;
}

.upload_agg {
  font-size: 1.3rem !important;
}

.remove_formcss {
  display: flex !important;
  flex-direction: column !important;
  gap: .5rem;
}


.Modal_bodycss {
  height: 15rem !important;
}

.upload_aggrement {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.agreement-file-error-text {
  margin-top: 13px;
  font-size: 13px;
}

.settingsrno {
  padding-left: 13px !important;
}

.tlinterview {
  text-align: center;
}

.thselected {
  text-align: center;
}

.tdselectedin {
  text-align: center;
}

.threjected {
  text-align: center;
}

.thhold {
  text-align: center;
}

.thcancel {
  text-align: center;
}

.thnoaction {
  text-align: center;
}